// These are currently in two places. Either move to src/routes or have src/routes
// import them.
export const LANDING = '/';
export const FORM = '/form';
export const COMPLETED = '/completed';

export const Z_TERMS_LINK = 'https://z.co.nz/';
export const HOME = 'https://z.co.nz/';
export const Z_EMAIL = 'email@z.co.nz';
export const AIRPOINTS_TERMS_LINK =
  'https://business.z.co.nz/terms-and-conditions/airpoints/';
export const PRIVACY_POLICY_LINK = 'https://z.co.nz/privacy/';
